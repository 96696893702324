








import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import { CompanyRoutes } from '@/shared/router/company'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import UserService from '@/shared/modules/user/user.service'
import permissions from '@/shared/helpers/permissions.helper'
import Company from '@/shared/modules/company/company.model'
import http from '@/shared/http'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import Employee from '@/shared/modules/employee/employee.model'
import DataTableTab from '@/shared/classes/components/data-table/data-table-tab';
import User from '@/shared/modules/user/user.model';
import { AuthGetters } from '@/shared/store/auth/auth.getters';
import TaskType from '@/shared/modules/task-type/task-type.model';
import router from '@/shared/router';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class EmployeesIndex extends Vue {
  table = new DataTableBase()
    .setModel(Employee)
    .setEndpoint(`company/${ this.company.slug }/employees`)
    .setDoubleClick(this.editEmployee)
    .setActiveTab(1)
    .setTabs([
      new DataTableTab()
        .setKey('all')
        .setTitle(__('company.views.employees.index.table.tabs.all')),
      new DataTableTab()
        .setKey('active')
        .setTitle(__('company.views.employees.index.table.tabs.active')),
      new DataTableTab()
        .setKey('inactive')
        .setTitle(__('company.views.employees.index.table.tabs.inactive')),
    ])
    .setHeaders([
      new DataTableHeader()
        .setKey('full_name')
        .setEntryKey('fullName')
        .setText(__('company.views.employees.index.table.columns.full-name')),
      new DataTableHeader()
        .setEntryKey('email')
        .setText(__('company.views.employees.index.table.columns.email')),
      new DataTableHeader()
        .setKey('role.name')
        .setText(__('company.views.employees.index.table.columns.role'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('position.name')
        .setText(__('company.views.employees.index.table.columns.position'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('status.name')
        .setText(__('company.views.employees.index.table.columns.status'))
        .setSortable(false),
    ])
    .setFilters([
      new DataTableFilter()
        .setOperator(FilterOperators.in)
        .setKeyIsPrimary()
        .setField(
          new SelectField()
            .isMultiple()
            .setKey('role.uuid')
            .setTitle(__('company.views.employees.index.table.filters.role'))
            .loadItems({ endpoint: '/roles', value: 'uuid', title: 'name',  })
        ),
      new DataTableFilter()
        .setOperator(FilterOperators.in)
        .setKeyIsPrimary()
        .setField(
          new SelectField()
            .isMultiple()
            .setKey('position.uuid')
            .setTitle(__('company.views.employees.index.table.filters.position'))
            .loadItems({ endpoint: '/positions', value: 'uuid', title: 'name',  })
        )
    ])
    .setActions([
      new DataTableAction()
        .setIcon('mdi-login')
        .setTitle(__('company.views.employees.index.table.actions.go-in'))
        .setPermissions([ permissions.company.employees.loginLike ])
        .setAction((item: Employee) => UserService.loginLike(item)),
      new EditAction()
        .setPermissions([
          permissions.company.employees.update
        ]),
      new DeleteAction()
        .setTitle(__('company.views.employees.index.table.actions.deactivate.button'))
        .setPermissions([ permissions.company.employees.deactivate ])
        .setAction((item: Employee) => this.$store.dispatch(GlobalActions.showDialog, {
          show: true,
          component: AreYouSureDialog,
          meta: {
            title: __('company.views.employees.index.table.actions.deactivate.title'),
            text: __('company.views.employees.index.table.actions.deactivate.text', { fullName: item.fullName }),
            onYes: () => http.post(`company/${ this.company.slug }/employees/${ item.uuid }/deactivate`)
              .then(() => this.tableRef.refresh())
          }
        }))
    ])

  navigateToCreate(): void {
    this.$router.push({ name: CompanyRoutes.employeesCreate })
  }

  get tableRef(): any {
    return this.$refs.table
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  editEmployee(employee: Employee) {
    if (this.user.hasDynamicPermissions([permissions.company.employees.update])) {
      this.$router.push({ path: `${ router.currentRoute.path }/${ employee.uuid }/edit`})
    }
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
